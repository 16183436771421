import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
// import "../../../styles/Inventory.css";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import Loader from "../../generalComponents/Loader";
import {
	askDataKeyValue,
	askDataSimple,
} from "../../../utils/actions/firebase/firebaseActions";
import { PermisionsHRNoveltiesApp } from "./permisionsHRNovelties/permisionsHRNoveltiesApp";
import { PermisionsHRRequestsApp } from "./permisionsHRRequests/permisionsHRRequestsApp";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { PermisionsHRMakerRequestApp } from "./permisionsHRMakeRequests/permisionsHRMakerRequestApp";

export const PermisionsHumanResourcesApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [sohwNovelties, setSohwNovelties] = useState(false);
	const [showRequests, setShowRequests] = useState(false);
	const [showMakeRequests, setShowMakeRequests] = useState(false);
	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [userPermisions, setUserPermisions] = useState(null);

	useEffect(() => {
		askUsers();
		askPermisions();
	}, []);

	const askUsers = async () => {
		setLoading(true);
		const response = await askDataSimple("employees-" + company);
		if (response.statusResponse === true) {
			setUsers(response.data);
			setLoading(false);
		} else {
			setUsers(null);
			setError(response.error);
			setLoading(false);
		}
	};
	const askPermisions = () => {
		setLoading(true);
		const collectionName = "noveltiesViewPermisions-" + company;

		const q = query(
			collection(db, collectionName)
			// where("typePermision", "==", "NoveltiesView")
		);
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			setUserPermisions(dataList);
			setLoading(false);
		});
	};

	const showItem = (value) => {
		if (value === "novelties") {
			setSohwNovelties(true);
		}
		if (value === "requests") {
			setShowRequests(true);
		}
		if (value === "MakeRequests") {
			setShowMakeRequests(true);
		}
	};

	const hideItem = (value) => {
		if (value === "novelties") {
			setSohwNovelties(false);
		}
		if (value === "requests") {
			setShowRequests(false);
		}
		if (value === "MakeRequests") {
			setShowMakeRequests(false);
		}
	};

	return (
		<div>
			{localUser.type === "3" ? (
				<div>
					<article className="titulo11">
						Permisos de Recursos Humanos ({company})
					</article>
					{loading ? (
						<Loader />
					) : (
						<div>
							{users && (
								<div>
									<div>
										<div className="menuPermisionsInventory">
											<article className="titulo8">Ver Novedades</article>
											{sohwNovelties ? (
												<button
													className="btnShowPermisions"
													onClick={() => hideItem("novelties")}
												>
													Ocultar
												</button>
											) : (
												<button
													onClick={() => showItem("novelties")}
													className="btnShowPermisions"
												>
													Ver
												</button>
											)}
										</div>
										{sohwNovelties && userPermisions && (
											<PermisionsHRNoveltiesApp
												users={users}
												userPermisions={userPermisions}
											/>
										)}
									</div>
									<div>
										<div className="menuPermisionsInventory">
											<article className="titulo8">
												Ver y Tramitar Solicitudes
											</article>
											{showRequests ? (
												<button
													className="btnShowPermisions"
													onClick={() => hideItem("requests")}
												>
													Ocultar
												</button>
											) : (
												<button
													onClick={() => showItem("requests")}
													className="btnShowPermisions"
												>
													Ver
												</button>
											)}
										</div>
										{showRequests && (
											<PermisionsHRRequestsApp
												users={users}
												userPermisions={userPermisions}
											/>
										)}
									</div>

									<div>
										<div className="menuPermisionsInventory">
											<article className="titulo8">Hacer Solicitudes</article>
											{showMakeRequests ? (
												<button
													className="btnShowPermisions"
													onClick={() => hideItem("MakeRequests")}
												>
													Ocultar
												</button>
											) : (
												<button
													onClick={() => showItem("MakeRequests")}
													className="btnShowPermisions"
												>
													Ver
												</button>
											)}
										</div>
										{showMakeRequests && (
											<PermisionsHRMakerRequestApp
												users={users}
												userPermisions={userPermisions}
											/>
										)}
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
