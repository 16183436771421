import React, { useEffect, useState } from "react";
import { EmployeesTableRow } from "./employeesTableRow";

export const EmployeesTable = ({
	documents,
	setShowForm,
	setDocumentToEdit,
	setDocumentToDelete,
	deleteDocument,
	setShowEmploye,
}) => {
	const [data, setData] = useState(documents);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const [filters, setFilters] = useState({
		name: "",
		email: "",
		area: "",
		status: "",
		document: "",
		phone: "",
	});

	useEffect(() => {
		setData(documents);
	}, [documents]);

	function handleSearch(e, columnName) {
		const { value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[columnName]: value.toLowerCase(),
		}));
	}
	// const filteredData = data.filter((item) =>
	// 	Object.entries(filters).every(([key, value]) =>
	// 		item[key].toLowerCase().includes(value)
	// 	)
	// );

	const filteredData = data.filter((item) =>
		Object.entries(filters).every(([key, value]) => {
			const itemValue = item[key];

			// Verificar si el filtro está vacío
			if (value === "") return true;

			// Verificar si itemValue es una cadena antes de usar toLowerCase
			if (typeof itemValue === "string") {
				return itemValue.toLowerCase().includes(value.toLowerCase());
			}

			// Si itemValue no es una cadena, devolver false
			return false;
		})
	);

	return (
		<div>
			<h3 className="titulo1">LISTA DE EMPLEADOS</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						{/* <th className="titleSearchColumn01">ID</th> */}
						<th className="titleSearchColumn01">
							<label className="titulo14">ID</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.document}
								onChange={(e) => handleSearch(e, "document")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Nombre</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.name}
								onChange={(e) => handleSearch(e, "name")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Email</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.email}
								onChange={(e) => handleSearch(e, "email")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Teléfono</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.phone}
								onChange={(e) => handleSearch(e, "phone")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Area</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.area}
								onChange={(e) => handleSearch(e, "area")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Estado</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.status}
								onChange={(e) => handleSearch(e, "status")}
							/>
						</th>
						<th className="titleTableDocuments03">Ver</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						filteredData.map((el) => (
							<EmployeesTableRow
								key={el.id}
								el={el}
								setDocumentToEdit={setDocumentToEdit}
								setDocumentToDelete={setDocumentToDelete}
								deleteDocument={deleteDocument}
								setShowForm={setShowForm}
								deleteDocument={deleteDocument}
								setShowEmploye={setShowEmploye}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
