import React from "react";
import { Redirect } from "react-router";
import NavbarBpw from "../generalComponents/NavbarBpw";

export const DataVersionApp = () => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<NavbarBpw />

					<aritcle className="titulo9">
						Versión: ProductionV03 github Noviembre 04 2024 (Reorganización de
						tareas por fecha, realizado desde la apilcación ya no desde la base
						de datos. )
					</aritcle>
					<aritcle className="titulo9">
						Versión: ProductionV03 github Octubre 13 2024 (creación de usuarios
						desde cada empresa )
					</aritcle>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
