import React from "react";

const CrudUsuariosTableRow = ({
	el,
	setUserToEdit,
	deleteUser,
	setShowForm,
}) => {
	let { id, name, email, type } = el;
	const userXedit = {
		id: el.id,
		uid: el.uid,
		area: el.area,
		name: el.name,
		email: el.email,
		type: el.type,
		company: el.company,
		agency: el.agency,
		group: el.group,
	};
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments0">{name}</td>
			<td className="colTableDocuments0">{email}</td>
			<td className="colTableDocuments0">{type}</td>
			<td className="colTableDocuments0">
				<button
					onClick={() => {
						setUserToEdit(el);
						setShowForm(true);
					}}
					className="btnViewDocuments"
				>
					Editar
				</button>
				<button onClick={() => deleteUser(el)} className="btnDeleteDocuments">
					Eliminar
				</button>
			</td>
		</tr>
	);
};

export default CrudUsuariosTableRow;
