import { initializeApp } from "firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	addDoc,
	onSnapshot,
	doc,
	updateDoc,
	collection,
	getFirestore,
} from "firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

import {
	askDataSimple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";
import { convertToLowerCase } from "../../../utils/actions/generlaFunctions";

export const UsersForm = ({
	company,
	user,
	localUser,
	users,
	documentToEdit,
	setDocumentToEdit,
	setShowForm,
}) => {
	const initialForm = {
		id: null,
		uid: "",
		name: "",
		email: "",
		type: "",
		company: company,
		agency: "",
		phone: "",
		typeDocument: "",
		document: "",
		expeditionDate: "",
		expirateDate: "",
		contractType: "",
		adress: "",
		dateIntake: "",
		dateOut: "",
		eps: "",
		fondoPension: "",
		fondoCesantias: "",
		arl: "",
		cajaCompensacion: "",
		status: "activo",
		resource: "",
		area: "",
		cargo: "",
		salary: "",
		dateCreation: "",
		userCreation: "",
		userUpdate: "",
		dateUpdate: "",
		inmediateBoss: "",
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const auth = getAuth();
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [agencies, setAgencies] = useState([]);
	const [areas, setAreas] = useState([]);

	useEffect(() => {
		askAgencies();
		askAreas();

		// askUsers();
		if (documentToEdit) {
			setForm(documentToEdit);
		} else {
			setForm(initialForm);
		}
	}, [documentToEdit]);

	const askAgencies = async () => {
		setLoading(true);
		const nameCollection = "agencies-" + company;
		const respuesta = await askDataSimple(nameCollection);
		if (respuesta.statusResponse === true) {
			setAgencies(respuesta.data);
			setLoading(false);
		} else {
			// setAgencies([]);
			// setError(respuesta.error);
			setLoading(false);
		}
	};

	const askAreas = async () => {
		setLoading(true);
		const nameCollection = "areas-" + company;
		const respuesta = await askDataSimple(nameCollection);
		if (respuesta.statusResponse === true) {
			setAreas(respuesta.data);
			setLoading(false);
		} else {
			// setAgencies([]);
			// setError(respuesta.error);
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmitx = async (e) => {
		e.preventDefault();
		console.log("formulario es: ", form);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!form.name) {
			alert("Debe colocar un nombre...");
			return;
		}
		// form.name = capitalizeWords(form.name);

		if (!form.email) {
			alert("Debe llenar el campo correo electrónico...");
			return;
		}
		form.email = convertToLowerCase(form.email);

		if (!form.type || form.type === "Tipo de usuario") {
			alert("Debe seleccionar el campo Tipo de Usuairo...");
			return;
		}
		if (!form.agency || form.agency === "Sucursal") {
			alert("Debe seleccionar el campo Sucursal...");
			return;
		}
		if (!form.area || form.area === "Area") {
			alert("Debe seleccionar el campo Area...");
			return;
		}

		setLoading(true);
		const fechita = new Date();
		const year = fechita.getFullYear();
		const month = fechita.getMonth() + 1; // Los meses son indexados desde 0, así que sumamos 1
		const day = fechita.getDate();
		const twoDigits = (number) => (number < 10 ? `0${number}` : number);
		const actualDate = `${year}-${twoDigits(month)}-${twoDigits(day)}`;
		const nameCollection = "employees-" + company;
		if (form.id === null) {
			const response = users.filter((user) => user.email === form.email);

			if (response.length > 0) {
				setError("Usuario con este correo ya existe");
				setLoading(false);
				return;
			} else {
				form.dateCreation = actualDate;
				form.userCreation = localUser.email;

				await createUserWithEmailAndPassword(auth, form.email, "123456")
					.then((userCredential) => {
						form.uid = userCredential.user.uid;
						addDoc(collection(db, nameCollection), form).then(
							async (response) => {
								if (response.id) {
									const docRef = doc(db, nameCollection, response.id);
									await updateDoc(docRef, {
										id: response.id,
									});
									form.id = response.id;
								}
								let mesageConfirm = window.confirm(
									`Se ha grabado el usuario y empleado`
								);
								if (mesageConfirm) {
									return;
								} else {
									return;
								}
							}
						);
						setError(null);
					})
					.catch((error) => {
						console.log("error: ", error);
						const errorMessage = error.message;
						setError(errorMessage);
					});

				setLoading(false);
				setShowForm(false);
			}
		} else {
			await updateDocumentById(nameCollection, form.id, form);
			setDocumentToEdit(null);
			// setDocumentToEdit(initialForm);

			setLoading(false);
			setShowForm(false);
		}
	};

	const handleReset = () => {
		setForm(initialForm);
		setDocumentToEdit(null);
	};

	const closeForm = () => {
		if (form.id === null) {
			setShowForm(false);
		} else {
			setDocumentToEdit(null);
			// setDocumentToEdit(initialForm);
			setShowForm(false);
		}
	};

	const handleSelectList = async (selectedOption, { name }) => {
		const value = selectedOption.value;
		setForm({
			...form,
			[name]: value,
		});
	};

	return (
		<div className="conten0">
			<div className="conten1 ">
				<article className="tab20 font20 color3 padding20">
					{documentToEdit ? "Editar Datos Usuario" : "Agregar Nuevo Usuario"}
				</article>
				<form onSubmit={handleSubmit} className="formRH">
					{loading ? (
						<Loader />
					) : (
						<div>
							{documentToEdit ? (
								<article className="titulo1">e-mail: {form.email}</article>
							) : (
								<input
									type="email"
									name="email"
									placeholder="Correo electrónico"
									onChange={handleChange}
									value={form.email}
									className="input1"
								/>
							)}
							<input
								type="text"
								name="name"
								placeholder="Nombre y Apellido"
								onChange={handleChange}
								value={form.name}
								className="input1"
							/>
							<select
								name="type"
								onChange={handleChange}
								value={form.type}
								className="input1"
							>
								<option selected>Tipo de usuario</option>

								{localUser.type === "1" && (
									<option value="1">SuperAdministrador</option>
								)}
								{localUser.type === "1" && <option value="2">Gerencial</option>}
								<option value="3">Supervisor</option>
								<option value="4">Operario</option>
							</select>
							<select
								name="agency"
								onChange={handleChange}
								value={form.agency}
								className="input1"
							>
								<option selected>Sucursal</option>
								{agencies.map((agency) => (
									<option key={agency.id} value={agency.agency}>
										{agency.agency}
									</option>
								))}
							</select>
							<select
								name="area"
								onChange={handleChange}
								value={form.area}
								className="input1"
							>
								<option selected>Area</option>
								{areas.map((area) => (
									<option key={area.id} value={area.area}>
										{area.area}
									</option>
								))}
							</select>

							<input
								type="submit"
								value="Enviar"
								className="btn-send-accounts"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeForm}
								className="btn-close-accounts"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
