import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import {
	query,
	addDoc,
	onSnapshot,
	doc,
	updateDoc,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig, storage } from "../../../config/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import {
	askDataKeyValue,
	askDataSimple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Message from "../../generalComponents/Message";
import ProgressBar from "../../generalComponents/ProgressBar";
import Select from "react-select";

export const EmployeesForm = ({
	user,
	dataBase2,
	documentToEdit,
	setDocumentToEdit,
	company,
	setShowForm,
	closeModal1,
}) => {
	const initialForm = {
		id: null,
		uid: "",
		name: "",
		email: "",
		type: "",
		company: company,
		agency: "",
		phone: "",
		typeDocument: "",
		document: "",
		expeditionDate: "",
		expirateDate: "",
		contractType: "",
		adress: "",
		dateIntake: "",
		dateOut: "",
		eps: "",
		fondoPension: "",
		fondoCesantias: "",
		arl: "",
		cajaCompensacion: "",
		status: "activo",
		resource: "",
		area: "",
		cargo: "",
		salary: "",
		dateCreation: "",
		userCreation: "",
		userUpdate: "",
		dateUpdate: "",
		inmediateBoss: "",
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [entitiesList, setEntitiesList] = useState(null);
	const [listARL, setListARL] = useState(null);
	const [listEPS, setlistEPS] = useState(null);
	const [listPENSIONES, setListPENSIONES] = useState(null);
	const [listCESANTIAS, setListCESANTIAS] = useState(null);
	const [listCAJA, setListCAJA] = useState(null);
	const [listCARGOS, setListCARGOS] = useState(null);
	const [listTIPOID, setListTIPOID] = useState(null);
	const [listTIPOCONTRATO, setListTIPOCONTRATO] = useState(null);
	const [listAreas, setListAreas] = useState(null);
	const [userList, setUserList] = useState(null);
	const [progressLoad, setProgressLoad] = useState(-1);

	useEffect(() => {
		askEntities();
		askAreas();
		askUsers();
		if (documentToEdit) {
			setForm(documentToEdit);
		} else {
			setForm(initialForm);
		}
	}, [documentToEdit]);

	const askEntities = () => {
		const nameCollection = "entitiesRRHH-" + company;
		// console.log("navegador:", navigator.geolocation);
		setLoading(true);
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setEntitiesList(dataList);
				setListARL(
					convertEntity(dataList.filter((item) => item.type == "ARL"))
				);
				setlistEPS(
					convertEntity(dataList.filter((item) => item.type == "EPS"))
				);
				setListPENSIONES(
					convertEntity(dataList.filter((item) => item.type == "PENSIONES"))
				);
				setListCESANTIAS(
					convertEntity(dataList.filter((item) => item.type == "CESANTIAS"))
				);
				setListCAJA(
					convertEntity(dataList.filter((item) => item.type == "CAJA"))
				);
				setListCARGOS(
					convertEntity(dataList.filter((item) => item.type == "CARGOS"))
				);
				setListTIPOID(
					convertEntity(dataList.filter((item) => item.type == "TIPO ID"))
				);
				setListTIPOCONTRATO(
					convertEntity(dataList.filter((item) => item.type == "TIPO CONTRATO"))
				);
				setLoading(false);
			},
			(error) => {
				// console.log("error", error);
				setEntitiesList(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askUsers = async () => {
		setLoading(true);
		const response = await askDataSimple("employees-" + company);
		if (response.statusResponse === true) {
			setUserList(convertUser(response.data));
			setLoading(false);
		} else {
			setUserList(null);
			setError(response.error);
			setLoading(false);
		}
	};

	const askAreas = () => {
		const nameCollection = "areas-" + company;

		setLoading(true);
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setEntitiesList(dataList);
				setListAreas(convertArea(dataList));
				setLoading(false);
			},
			(error) => {
				// console.log("error", error);
				setListAreas(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const convertEntity = (list) => {
		let selectEntity = [];
		list.map((item) => {
			const lineList = {
				value: item.name,
				label: item.name,
			};
			selectEntity.push(lineList);
		});
		return selectEntity;
	};
	const convertArea = (list) => {
		let selectArea = [];
		list.map((item) => {
			const lineList = {
				value: item.area,
				label: item.area,
			};
			selectArea.push(lineList);
		});
		return selectArea;
	};

	const convertUser = (list) => {
		let selectUser = [];
		list.map((item) => {
			const lineList = {
				value: item.email,
				label: item.email + " - " + item.name,
			};
			selectUser.push(lineList);
		});
		return selectUser;
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!form.name) {
			alert("Debe colocar un nombre...");
			return;
		}
		if (!form.email) {
			alert("Debe llenar el campo correo electrónico...");
			return;
		}
		if (!form.phone) {
			alert("Debe llenar el campo Número de Teléfono...");
			return;
		}
		if (!form.document) {
			alert("Debe llenar el campo documento...");
			return;
		}
		setLoading(true);
		const fechita = new Date();
		// const actualDate = fechita.toLocaleDateString();
		const year = fechita.getFullYear();
		const month = fechita.getMonth() + 1; // Los meses son indexados desde 0, así que sumamos 1
		const day = fechita.getDate();
		const twoDigits = (number) => (number < 10 ? `0${number}` : number);
		const actualDate = `${year}-${twoDigits(month)}-${twoDigits(day)}`;
		const nameCollection = "employees-" + company;
		if (form.id === null) {
			const key = "document";
			const value = form.document;
			const response = await askDataKeyValue(nameCollection, key, value);

			if (response.statusResponse === true) {
				if (response.data.length > 0) {
					setError("Este Documento ya existe");
					setLoading(false);
					return;
				} else {
					const key2 = "email";
					const value2 = form.email;
					const response2 = await askDataKeyValue(nameCollection, key2, value2);
					if (response2.statusResponse === true) {
						if (response2.data.length > 0) {
							setError("Este Email ya existe");
							setLoading(false);
							return;
						} else {
							form.userCreation = user;
							form.dateCreation = actualDate;
							await addDoc(collection(db, nameCollection), form).then(
								async (res) => {
									if (res.id) {
										const docRef = doc(db, nameCollection, res.id);
										await updateDoc(docRef, {
											id: res.id,
										});
									}
								}
							);
							setLoading(false);
							setShowForm(false);
						}
					} else {
						setError(response2.error);
						setLoading(false);
						return;
					}
				}
			} else {
				setError(response.error);
				setLoading(false);
				return;
			}
		} else {
			form.userUpdate = user;
			form.dateUpdate = actualDate;
			updateDocumentById(nameCollection, form.id, form);
			setDocumentToEdit(form);
			setLoading(false);
			closeModal1();
		}
	};

	const handleReset = () => {
		setForm(initialForm);
		setDocumentToEdit(null);
	};

	const closeForm = () => {
		if (form.id === null) {
			setShowForm(false);
		} else {
			setDocumentToEdit(documentToEdit);
			closeModal1();
		}
	};

	const handleSelectList = async (selectedOption, { name }) => {
		const value = selectedOption.value;
		setForm({
			...form,
			[name]: value,
		});
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{documentToEdit ? "Editar Datos Empleado" : "Agregar Nuevo Empleado"}
				</h3>
				<form onSubmit={handleSubmit} className="formRH">
					{loading ? (
						<Loader />
					) : (
						<div>
							<div className="containerFormRH">
								<label className="humanLabel0">Correo electrónico *</label>
								{form.id === null ? (
									<input
										type="email"
										name="email"
										placeholder="Correo electrónico"
										onChange={handleChange}
										value={form.email}
										className="humanInput0"
									/>
								) : (
									<label className="humanInput0">{form.email}</label>
								)}

								<label className="humanLabel0">Nombres y Apellidos *</label>
								<input
									type="text"
									name="name"
									placeholder="Nombres y Apellidos"
									onChange={handleChange}
									value={form.name}
									className="humanInput0"
								/>

								<label className="humanLabel0">Tipo de documento *</label>
								<Select
									className="humanSelect0"
									options={listTIPOID}
									name="typeDocument"
									defaultInputValue={
										documentToEdit ? documentToEdit.typeDocument : ""
									}
									value={
										form.typeDocument
											? { label: form.typeDocument, value: form.typeDocument }
											: null
									}
									onChange={handleSelectList}
									placeholder="Tipo de documento ?"
								/>
								<label className="humanLabel0">Número de documento *</label>
								<input
									type="text"
									name="document"
									placeholder="Número de documento"
									onChange={handleChange}
									value={form.document}
									className="humanInput0"
								/>

								<label className="humanLabel0">Fecha de expedición id*</label>
								<input
									type="date"
									name="expeditionDate"
									placeholder="Fecha de expedicion id"
									onChange={handleChange}
									value={form.expeditionDate}
									className="humanInput0"
								/>
								<label className="humanLabel0">Fecha vencimiento id </label>
								<input
									type="date"
									name="expirateDate"
									placeholder="Fecha de vencimiento id"
									onChange={handleChange}
									value={form.expirateDate}
									className="humanInput0"
								/>
								<label className="humanLabel0">Número de Teléfono *</label>
								<input
									type="text"
									name="phone"
									placeholder="Número de Teléfono"
									onChange={handleChange}
									value={form.phone}
									className="humanInput0"
								/>
								<label className="humanLabel0">Dirección</label>
								<input
									type="text"
									name="adress"
									placeholder="Dirección"
									onChange={handleChange}
									value={form.adress}
									className="humanInput0"
								/>
								<label className="humanLabel0">Fecha de ingreso</label>
								<input
									type="date"
									name="dateIntake"
									placeholder="Fecha de ingreso"
									onChange={handleChange}
									value={form.dateIntake}
									className="humanInput0"
								/>
								<label className="humanLabel0">EPS</label>
								<Select
									className="humanSelect0"
									options={listEPS}
									name="eps"
									defaultInputValue={documentToEdit ? documentToEdit.eps : ""}
									value={form.eps ? { label: form.eps, value: form.eps } : null}
									onChange={handleSelectList}
									placeholder="EPS ?"
								/>
								<label className="humanLabel0">Fondo de Pensión</label>
								<Select
									className="humanSelect0"
									options={listPENSIONES}
									name="fondoPension"
									defaultInputValue={
										documentToEdit ? documentToEdit.fondoPension : ""
									}
									value={
										form.fondoPension
											? { label: form.fondoPension, value: form.fondoPension }
											: null
									}
									onChange={handleSelectList}
									placeholder="Fondo de Pensión ?"
								/>
								<label className="humanLabel0">Fondo de Cesantías</label>
								<Select
									className="humanSelect0"
									options={listCESANTIAS}
									name="fondoCesantias"
									defaultInputValue={
										documentToEdit ? documentToEdit.fondoCesantias : ""
									}
									value={
										form.fondoCesantias
											? {
													label: form.fondoCesantias,
													value: form.fondoCesantias,
											  }
											: null
									}
									onChange={handleSelectList}
									placeholder="Cesantías ?"
								/>
								<label className="humanLabel0">ARL</label>
								<Select
									className="humanSelect0"
									options={listARL}
									value={form.arl ? { label: form.arl, value: form.arl } : null}
									name="arl"
									defaultInputValue={documentToEdit ? documentToEdit.arl : ""}
									onChange={handleSelectList}
									placeholder="ARL ?"
								/>
								<label className="humanLabel0">Caja Compensación</label>
								<Select
									className="humanSelect0"
									options={listCAJA}
									name="cajaCompensacion"
									defaultInputValue={
										documentToEdit ? documentToEdit.cajaCompensacion : ""
									}
									value={
										form.cajaCompensacion
											? {
													label: form.cajaCompensacion,
													value: form.cajaCompensacion,
											  }
											: null
									}
									onChange={handleSelectList}
									placeholder="CAJA ?"
								/>
								<label className="humanLabel0">Area</label>
								<Select
									className="humanSelect0"
									options={listAreas}
									name="area"
									defaultInputValue={documentToEdit ? documentToEdit.area : ""}
									value={
										form.area ? { label: form.area, value: form.area } : null
									}
									onChange={handleSelectList}
									placeholder="Area ?"
								/>

								<label className="humanLabel0">Cargo</label>
								<Select
									className="humanSelect0"
									options={listCARGOS}
									name="cargo"
									defaultInputValue={documentToEdit ? documentToEdit.cargo : ""}
									value={
										form.cargo ? { label: form.cargo, value: form.cargo } : null
									}
									onChange={handleSelectList}
									placeholder="Cargo ?"
								/>
								<label className="humanLabel0">Tipo de Contrato</label>
								<Select
									className="humanSelect0"
									options={listTIPOCONTRATO}
									name="contractType"
									defaultInputValue={
										documentToEdit ? documentToEdit.contractType : ""
									}
									value={
										form.contractType
											? { label: form.contractType, value: form.contractType }
											: null
									}
									onChange={handleSelectList}
									placeholder="Tipo de Contrato ?"
								/>
								<label className="humanLabel0">Jefe Inmediato</label>
								<Select
									className="humanSelect0"
									options={userList}
									name="inmediateBoss"
									defaultInputValue={
										documentToEdit ? documentToEdit.inmediateBoss : ""
									}
									value={
										form.inmediateBoss
											? { label: form.inmediateBoss, value: form.inmediateBoss }
											: null
									}
									onChange={handleSelectList}
									placeholder="Jefe Inmediato ?"
								/>
								<label className="humanLabel0">Salario</label>
								<input
									type="number"
									name="salary"
									placeholder="Salario"
									onChange={handleChange}
									value={form.salary}
									className="humanInput0"
								/>
								<label className="humanLabel0">
									Fecha Terminación Contrato
								</label>
								<input
									type="date"
									name="dateOut"
									placeholder="Fecha Terminación Contrato"
									onChange={handleChange}
									value={form.dateOut}
									className="humanInput0"
								/>
							</div>

							{error && <Message mensaje={error} bgColor="#dc3545" />}
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							{!documentToEdit && (
								<input
									type="reset"
									value="Limpiar"
									onClick={handleReset}
									className="btn-clean-reg-asign"
								/>
							)}
							<input
								type="reset"
								value="Cerrar"
								onClick={closeForm}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
