import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../config/firebaseConfig";
import { signIn } from "../../utils/actions/firebase/firebaseActions";
import "../styles/LoginForm.css";
import Loader from "./Loader";
import imagen from "./images/logoBPWhorizontal.png";
import Modal from "../generalComponents/Modal";
import { useModal } from "./hooks/useModal";
import { LoginForgotForm } from "./LoginForgotForm";

function Login() {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [listCompanies, setListCompanies] = useState(null);
	const [mail, setMail] = useState("");
	const [contrasena, setContrasena] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [usuario, setUsuario] = useState({
		email: "",
		company: "",
		group: "",
		id: "",
		name: "",
		agency: "",
		type: "",
	});

	useEffect(() => {
		askAccounts();
	}, []);

	const askAccounts = async () => {
		const q = query(collection(db, "accounts"));
		onSnapshot(q, (querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			console.log("dataList: ", dataList);
			let lCompanies = [];
			dataList.map((item) => lCompanies.push(item.company));
			console.log("lista de compañias: ", lCompanies);
			setListCompanies(lCompanies);
		});
	};

	const doLogin = async (e) => {
		e.preventDefault();

		if (!usuario.company || usuario.company === "seleccione compañia...") {
			alert("Debe seleccionar una compañia...");
			return;
		}
		setLoading(true);
		const result = await signIn(mail, contrasena, usuario.company);

		console.log("**** result:", result);

		if (!result.statusResponse) {
			setLoading(false);
			setMail("");
			setContrasena("");
			alert(result.error);
		} else {
			console.log("largo: ", result.data.length);
			if (result.data.length === 0) {
				console.log("sin permisos....");
				alert("USUARIO NO TIENE PERMISOS ACTIVOS");

				setLoading(false);
				return;
			}
			console.log("si hay....");
			localStorage.setItem("userLocal", JSON.stringify(result.data[0]));
			localStorage.setItem("usuariosEmpresa", result.data[0].company);
			await askModules(usuario.company);
			console.log(" va a grabar: ", result);
			setUsuario(result.data[0]);
			setLoading(false);
		}
	};

	const askModules = async (comp) => {
		const q = query(
			collection(db, "adminModules"),
			where("company", "==", comp)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// setDataBase(dataList);
				if (dataList.length > 0) {
					if (dataList[0].modules.includes("Procesos")) {
						localStorage.setItem("ModuleKeyProcess", "si");
					} else {
						localStorage.setItem("ModuleKeyProcess", "no");
					}
					if (dataList[0].modules.includes("Documentos")) {
						localStorage.setItem("ModuleKeyDocuments", "si");
					} else {
						localStorage.setItem("ModuleKeyDocuments", "no");
					}
					if (dataList[0].modules.includes("Capacitación")) {
						localStorage.setItem("ModuleKeyCapacitation", "si");
					} else {
						localStorage.setItem("ModuleKeyCapacitation", "no");
					}
					if (dataList[0].modules.includes("Evaluación")) {
						localStorage.setItem("ModuleKeyEvaluation", "si");
					} else {
						localStorage.setItem("ModuleKeyEvaluation", "no");
					}
					return;
				} else {
					localStorage.setItem("ModuleKeyProcess", "no");
					localStorage.setItem("ModuleKeyDocuments", "no");
					localStorage.setItem("ModuleKeyCapacitation", "no");
					localStorage.setItem("ModuleKeyEvaluation", "no");
					return;
				}
				// setLoading(false);
			},
			(error) => {
				localStorage.setItem("ModuleKeyProcess", "no");
				localStorage.setItem("ModuleKeyDocuments", "no");
				localStorage.setItem("ModuleKeyCapacitation", "no");
				localStorage.setItem("ModuleKeyEvaluation", "no");
				return;
			}
		);
	};

	const forgotPasword = () => {
		openModal1();
	};

	const handleChange = (e) => {
		setUsuario({
			...usuario,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<div>
			<div className="titulo">
				<img src={imagen} className="icono" />
			</div>
			<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
				<LoginForgotForm closeModal={closeModal1} />
			</Modal>
			<div className="container2">
				<h2 className="titleLogin">Formulario de Ingreso</h2>
				{listCompanies ? (
					<div className="login">
						<article className="fondo">
							<form onSubmit={(e) => doLogin(e)}>
								{loading && <Loader />}
								<select
									name="company"
									onChange={handleChange}
									value={usuario.company}
									// className="input1"
									className="form-control mb-2"
								>
									<option selected>Seleccione compañia...</option>
									{listCompanies.map((compa) => (
										<option>{compa}</option>
									))}
								</select>

								<input
									type="email"
									className="form-control mb-2"
									placeholder="Ingresa tu email..."
									onChange={(text) => setMail(text.target.value)}
									value={mail}
								/>
								<input
									type="password"
									className="form-control mb-2"
									placeholder="Ingresa tu contraseña..."
									onChange={(text) => setContrasena(text.target.value)}
									value={contrasena}
								/>

								<Link onClick={forgotPasword} className="linkForgotPassword">
									olvidó su constraseña...
								</Link>

								<button className="buttonLogin">Iniciar Sesión</button>
							</form>
							{usuario.type === "1" ? (
								// <Redirect to="/accounts" />
								<Redirect to="/super_administrator" />
							) : usuario.type === "2" ? (
								// <Redirect to="/process-list" />
								<Redirect to="/administrator_company" />
							) : usuario.type === "3" ? (
								// <Redirect to="/supervisor-company/reg_procesos" />
								<Redirect to="/supervisor_company" />
							) : (
								usuario.type === "4" && <Redirect to="/worker-company" />
							)}
						</article>
					</div>
				) : (
					<article className="titleLogin">sin datos...</article>
				)}
			</div>
		</div>
	);
}
export default Login;
