export function capitalizeWords(str) {
	// Convierte toda la cadena a minúsculas y luego separa las palabras en un array
	const words = str.toLowerCase().split(" ");

	// Recorre el array y convierte la primera letra de cada palabra a mayúsculas
	const capitalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});

	// Une las palabras en una cadena y la devuelve
	return capitalizedWords.join(" ");
}

export function convertirToUppercase(str) {
	return str.toUpperCase();
}

export function convertToLowerCase(str) {
	return str.toLowerCase();
}

export function calcularEdad(fechaNacimiento) {
	const hoy = new Date();
	const nacimiento = new Date(fechaNacimiento);
	let edad = hoy.getFullYear() - nacimiento.getFullYear();
	const mes = hoy.getMonth() - nacimiento.getMonth();

	if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
		edad--;
	}

	return edad;
}

export function scrollToTop() {
	//los dos funcionan pero por id es más preciso....
	// document.querySelector(".contentMenuVertical").scrollTop = 0;
	document.getElementById("algunaCosa").scrollTop = 0;
}
