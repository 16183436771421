import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router";
import {
	addData,
	askDataKeyValue,
	askDataSimple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import CrudUsuariosTable from "./CrudUsuariosTable";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import { UsersForm } from "./UsersForm";

const CrudUsuariosApp = (props) => {
	let { company } = useParams();
	const [db, setDb] = useState(null);
	const [userToEdit, setUserToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [showForm, setShowForm] = useState(false);

	const askUsers = async () => {
		setLoading(true);
		const nameCollection = "employees-" + company;

		const respuesta = await askDataSimple(nameCollection);
		// const respuesta = await askUsuarios(empresa);
		if (respuesta.statusResponse === true) {
			setDb(respuesta.data);
			setLoading(false);
		} else {
			setDb(null);
			setError(respuesta.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askUsers();
	}, []);

	const clickForm = () => {
		// setDocumentToEdit(null);
		setShowForm(true);
	};

	const deleteUser = async (userToDelete) => {
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar el Usuario y Empleado ?`
			// `¿Estás seguro de eliminar el registro con el id '${userToDelete.id}'?`
		);

		if (isDelete) {
			setLoading(true);
			console.log("user to delete: ", userToDelete);
			const userInEmployee = await askDataKeyValue(
				"employees-" + company,
				"email",
				userToDelete.email
			);
			console.log("userInEmployee", userInEmployee);

			await deleteDocumentById(
				"employees-" + company,
				userInEmployee.data[0].id
			);
			await addData("usersDeleted", userToDelete);

			await askUsers();
			setLoading(false);
		} else {
			return;
		}
	};

	const updatePersonel = async () => {
		setLoading(true);
		const nameCollection = "employees-" + company;
		const resp1 = await askDataSimple(nameCollection);
		if (resp1.statusResponse) {
			const employeesList = resp1.data;
			// console.log("lista empleados: ", employeesList);
			const users = db;
			let filterUsers = users.filter((el) => el.name === "");

			function findUsersNotInEmployees(usersList, employeesList) {
				// Filtrar usuarios que no están en employeesList basándonos en el campo "email"
				const usersNotInEmp = employeesList.filter((employee) => {
					const foundEmplo = users.find(
						(user) => user.email === employee.email
					);
					return !foundEmplo;
				});

				return usersNotInEmp;
			}

			const result = findUsersNotInEmployees(users, employeesList);
			// console.log("los que falta crer:", result.length, " en total");
			result.map((user) => {
				// console.log("correo:", user.email);
			});

			filterUsers.map((fUser) => {
				employeesList.map((employee) => {
					if (fUser.email === employee.email) {
						fUser.name = employee.name;
						fUser.area = employee.area;
						fUser.type = "4";
					}
				});
			});
			// console.log("---- TERMINÓ DE CRUZAR -----");
			if (filterUsers.length === 0) {
				// console.log("sale si grabar nada");
				setLoading(false);
				return;
			}
			await Promise.all(
				users.map(async (user) => {
					await updateDocumentById("users", user.id, user);
				})
			);
			setLoading(false);
		}
		setError(resp1.error);
		setLoading(false);
	};

	if (showForm) {
		return (
			<div>
				{localUser.type === "1" ? <NavbarBpw /> : <NavbarBpw2 />}
				<UsersForm
					company={company}
					setShowForm={setShowForm}
					localUser={localUser}
					users={db}
					setDocumentToEdit={setUserToEdit}
					documentToEdit={userToEdit}
				/>
			</div>
		);
	} else {
		return (
			<div>
				{localUser.type === "1" || localUser.type === "2" ? (
					loading ? (
						<Loader />
					) : (
						<div>
							{localUser.type === "1" ? <NavbarBpw /> : <NavbarBpw2 />}
							<article className="containerTime03">
								<button onClick={clickForm} className="btn-create-document">
									Nuevo Usuario
								</button>
								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{db && (
									<CrudUsuariosTable
										company={company}
										user={db}
										setUserToEdit={setUserToEdit}
										deleteUser={deleteUser}
										setShowForm={setShowForm}
									/>
								)}
							</article>
						</div>
					)
				) : (
					// <Redirect to="/intruso" />
					<p>intruso</p>
				)}
			</div>
		);
	}
};

export default CrudUsuariosApp;
