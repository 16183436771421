import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import {
	askDataKeyValue,
	askDataSimple,
} from "../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import { PermisionsTimeClockApp } from "./permisionsTimeClock/permisionsTimeClockApp";
import { PermisionsTimeDataBasesApp } from "./permisionsTimeDataBases/permisionsTimeDataBasesApp";
import { PermisionsTimeReportApp } from "./permisionsTimeReport/permisionsTimeReportApp";
// import { PermisionsInventoryMasterApp } from "./permisionsInventoryMaster/permisionsInventoryMasterApp";
// import { PermisionsInventoryGeneralApp } from "./permisionsInventoryGeneral/permisionsInventoryGeneralApp";
// import { PermisionsInventoryTransfApp } from "./permisionsInventoryTransf/permisionsInventoryTransfApp";

export default function TimePermisionsApp() {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [showClock, setShowClock] = useState(false);
	const [showReports, setShowReports] = useState(false);
	const [showDataBases, setShowDataBases] = useState(false);
	const [users, setUsers] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		askUsers();
	}, []);

	const askUsers = async () => {
		setLoading(true);
		const nameCollection = "employees-" + company;
		const response = await askDataSimple(nameCollection);
		if (response.statusResponse === true) {
			setUsers(response.data);
			setLoading(false);
		} else {
			setUsers(null);
			setError(response.error);
			setLoading(false);
		}
	};

	const showItem = (value) => {
		if (value === "clock") {
			setShowClock(true);
		}
		if (value === "reports") {
			setShowReports(true);
		}
		if (value == "dataBase") {
			setShowDataBases(true);
		}
	};
	const hideItem = (value) => {
		if (value === "clock") {
			setShowClock(false);
		}
		if (value === "reports") {
			setShowReports(false);
		}
		if (value === "dataBase") {
			setShowDataBases(false);
		}
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					{error && <Message mensaje={error} bgColor="#dc3545" />}
					{loading ? (
						<Loader />
					) : (
						<div>
							<article className="titulo11">
								Permisos de Tiempos ({company})
							</article>

							<div className="menuPermisionsInventory">
								<article className="titulo8">Relojes</article>
								{showClock ? (
									<button
										className="btnShowPermisions"
										onClick={() => hideItem("clock")}
									>
										Ocultar
									</button>
								) : (
									<button
										onClick={() => showItem("clock")}
										className="btnShowPermisions"
									>
										Ver
									</button>
								)}
							</div>
							{showClock && users && <PermisionsTimeClockApp users={users} />}

							<div className="menuPermisionsInventory">
								<article className="titulo8">Informes de Tiempos</article>
								{showReports ? (
									<button
										className="btnShowPermisions"
										onClick={() => hideItem("reports")}
									>
										Ocultar
									</button>
								) : (
									<button
										onClick={() => showItem("reports")}
										className="btnShowPermisions"
									>
										Ver
									</button>
								)}
							</div>
							{showReports && users && (
								<PermisionsTimeReportApp users={users} />
							)}

							<div className="menuPermisionsInventory">
								<article className="titulo8">Bases de Datos de Tiempos</article>
								{showDataBases ? (
									<button
										className="btnShowPermisions"
										onClick={() => hideItem("dataBase")}
									>
										Ocultar
									</button>
								) : (
									<button
										onClick={() => showItem("dataBase")}
										className="btnShowPermisions"
									>
										Ver
									</button>
								)}
							</div>
							{showDataBases && users && (
								<PermisionsTimeDataBasesApp users={users} />
							)}
						</div>
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
}
