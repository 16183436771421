import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import {
	askDataSimple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import { useModal } from "../../generalComponents/hooks/useModal";
import "../../styles/HumanResources.css";
import { EmployeesForm } from "./employeesForm";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { EmployeesTable } from "./employeesTable";
import { EmployeCvApp } from "./employeCvApp";

const EmployessApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null);
	const [dataBase2, setDataBase2] = useState(null);
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [documentToDelete, setDocumentToDelete] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showEmploye, setShowEmploye] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askEmployees = async () => {
		setLoading(true);
		const q = query(collection(db, "employees-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askAreas = async () => {
		setLoading(true);
		const nameCollection = "areas-" + company;
		const respuesta = await askDataSimple(nameCollection);
		if (respuesta.statusResponse === true) {
			setDataBase2(respuesta.data);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		askEmployees();
		// askAreas();
	}, []);

	const deleteDocument = async (data) => {
		const nameCollection = "documents-list-" + company;
		const docId = data.id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Documento?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	const clickForm = () => {
		setDocumentToEdit(null);
		setShowForm(true);
	};

	const closeModal = () => {};

	if (showForm) {
		return (
			<div>
				<EmployeesForm
					setShowForm={setShowForm}
					company={company}
					user={localUser.email}
					setDocumentToEdit={setDocumentToEdit}
					documentToEdit={documentToEdit}
					closeModal1={closeModal}
				/>
			</div>
		);
	} else {
		if (showEmploye) {
			return (
				<div>
					<EmployeCvApp
						data={documentToEdit}
						setShowEmploye={setShowEmploye}
						setShowForm={setShowForm}
						company={company}
						user={localUser.email}
						setDocumentToEdit={setDocumentToEdit}
						documentToEdit={documentToEdit}
					/>
				</div>
			);
		} else {
			return (
				<div>
					<div>
						<article>
							<h2 className="titleProcess">EMPLEADOS ({company})</h2>
							{loading && <Loader />}
							{error && <Message mensaje={error} bgColor="#dc3545" />}
							{dataBase ? (
								<div>
									{/* <button onClick={clickForm} className="btn-create-document">
										Nuevo Empleado
									</button> */}
									<EmployeesTable
										documents={dataBase}
										setShowForm={setShowForm}
										setDocumentToEdit={setDocumentToEdit}
										setDocumentToDelete={setDocumentToDelete}
										deleteDocument={deleteDocument}
										setShowEmploye={setShowEmploye}
									/>
								</div>
							) : (
								<h3>No hay Empleados para mostrar</h3>
							)}
						</article>
					</div>
				</div>
			);
		}
	}
};

export default EmployessApp;
