import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { Redirect } from "react-router";
import {
	addDocumento,
	askDataSimple,
	deleteDocumentById,
	delFile,
	updateDocumentById,
} from "../../utils/actions/firebase/firebaseActions";
import Loader from "../generalComponents/Loader";
import Message from "../generalComponents/Message";
import CrudDocumentsTable from "./CrudDocumentsTable";
import { useModal } from "../generalComponents/hooks/useModal";
import Modal from "../generalComponents/Modal";
import CrudDocumentsForm from "./CrudDocumentsForm";
import { firebaseConfig } from "../../config/firebaseConfig";
import CrudDocumentsForm2 from "./CrudDocumentsForm2";
import "../styles/Documents.css";
import NavbarBpw2 from "../generalComponents/NavbarBpw2";
import NavbarBpw3 from "../generalComponents/NavbarBpw3";
import NavbarBpw4 from "../generalComponents/NavbarBpw4";

const CrudDocumentsApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null);
	const [dataBase2, setDataBase2] = useState(null);
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [documentToDelete, setDocumentToDelete] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askDocs = async () => {
		setLoading(true);

		if (localUser.type === "2") {
			const q = query(collection(db, "documents-list-" + company));
			onSnapshot(
				q,
				(querySnapshot) => {
					let dataList = querySnapshot.docs.map((d) => ({
						...d.data(),
					}));
					setDataBase(dataList);
					setLoading(false);
				},
				(error) => {
					setDataBase(null);
					setError(error);
					setLoading(false);
				}
			);
		} else {
			const q = query(
				collection(db, "documents-list-" + company),
				where("area", "in", [localUser.area, "Gestión"])
			);
			onSnapshot(
				q,
				(querySnapshot) => {
					let dataList = querySnapshot.docs.map((d) => ({
						...d.data(),
					}));
					setDataBase(dataList);
					setLoading(false);
				},
				(error) => {
					setDataBase(null);
					setError(error);
					setLoading(false);
				}
			);
		}
	};

	const askAreas = async () => {
		setLoading(true);
		const nameCollection = "areas-" + company;
		const respuesta = await askDataSimple(nameCollection);
		if (respuesta.statusResponse === true) {
			setDataBase2(respuesta.data);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		askDocs();
		askAreas();
	}, []);

	const createDocument = async (data) => {
		setLoading(true);
		const response = await addDocumento(data, company);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		// askDocs();
		setLoading(false);
	};

	const updateDocument = async (data) => {
		setLoading(true);
		const nameCollection = "documents-list-" + company;
		const docId = data.id;
		const changes = data;
		await updateDocumentById(nameCollection, docId, changes);
		setLoading(false);
	};

	const deleteDocument = async (data) => {
		console.log("para borrar el documento:", data);
		const nameCollection = "documents-list-" + company;
		const docId = data.id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Documento?`);
		if (isDelete) {
			setLoading(true);
			try {
				await delFile(data.resource);
			} catch (err) {
				setError("No se puedo borrar el archivo: ");
				return;
			}
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false); //uno para cada modal que se utilice
	const [isOpenModal2, openModal2, closeModal2] = useModal(false);

	const clickCrear = () => {
		setDocumentToEdit(null);
		openModal1();
	};

	return (
		<div>
			{localUser ? (
				<div>
					{localUser.type == "2" ? (
						<NavbarBpw2 />
					) : localUser.type === "3" ? (
						<NavbarBpw3 />
					) : (
						<NavbarBpw4 />
					)}
					<article className="containerTime03">
						{/* <p>Aquí se gestionan listas de procesos</p> */}
						<h2 className="titleProcess">DOCUMENTOS ({company})</h2>

						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<CrudDocumentsForm
								dataBase2={dataBase2}
								createDocument={createDocument}
								updateDocument={updateDocument}
								documentToEdit={documentToEdit}
								setDocumentToEdit={setDocumentToEdit}
								company={company}
								closeModal={closeModal1}
								setError={setError}
								isOpenModal1={isOpenModal1}
							/>
						</Modal>
						<Modal isOpen={isOpenModal2} closeModal={closeModal2}>
							<CrudDocumentsForm2
								updateDocument={updateDocument}
								documentToEdit={documentToEdit}
								setDocumentToEdit={setDocumentToEdit}
								company={company}
								closeModal={closeModal2}
								setError={setError}
							/>
						</Modal>

						{loading && <Loader />}
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{dataBase ? (
							<div>
								{localUser.type === "2" && (
									<button onClick={clickCrear} className="btn-create-document">
										Nuevo Documento
									</button>
								)}
								<CrudDocumentsTable
									documents={dataBase}
									setDocumentToEdit={setDocumentToEdit}
									setDocumentToDelete={setDocumentToDelete}
									deleteDocument={deleteDocument}
									openModal1={openModal1}
									openModal2={openModal2}
								/>
							</div>
						) : (
							<h3>No hay Documentos para mostrar</h3>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default CrudDocumentsApp;
