import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	where,
	onSnapshot,
	orderBy,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { Redirect } from "react-router-dom";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import "../../styles/RegProcesos.css";
import NavbarBpw4 from "../../generalComponents/NavbarBpw4";
import { firebaseConfig } from "../../../config/firebaseConfig";
import CrudRegAssignmentsTable from "./CrudRegAssignmentsTable";

const CrudRegAssignmentsApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [dataBase, setDataBase] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [seeDetail, setSeeDetail] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askAssignmentsUser = async () => {
		setLoading(true);
		const q = query(
			collection(db, "assignments-" + company),
			where("assignment", "==", localUser.email),
			where("status", "==", "abierto"),
			orderBy("date", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				dataList.map((item) => {
					item.newdata = new Date(item.date.split("/").reverse().join("-"));
				});
				const sortedDataList = dataList.sort((a, b) => {
					// Comparar directamente los objetos Date
					return b.newdata - a.newdata;
				});
				// setDataBase(dataList);
				const modifiedDataList = sortedDataList.map(
					({ newdata, ...rest }) => rest
				);
				// console.log("++++ datosNew:", modifiedDataList);
				setDataBase(modifiedDataList);
				// setRefresh(true);
				setLoading(false);
			},
			(error) => {
				console.log(error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askAssignmentsUser();
	}, []);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false); //uno para cada modal que se utilice

	const clickCrear = () => {
		// setProcessToEdit(null);
		openModal1();
	};

	const goSeeDetail = () => {
		setSeeDetail(true);
	};
	return (
		<div>
			{localUser.type === "4" && <NavbarBpw4 />}
			{localUser.type === "4" || localUser.type === "3" ? (
				<div>
					<article>
						{/* <p>Aquí se observan la etapas asignadas</p> */}
						<h3 className="titleProcess">ETAPAS ASIGNADAS ({company})</h3>

						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							{/* <CrudRegProcesosForm
								createRegProcess={createRegProcess}
								company={company}
								agency={localUser.agency}
								creator={email}
								area={area}
								closeModal={closeModal1}
							/> */}
						</Modal>

						{loading && <Loader />}
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{dataBase ? (
							<CrudRegAssignmentsTable
								regAssignments={dataBase}
								goSeeDetail={goSeeDetail}
								company={company}
							/>
						) : (
							<article className="titulo4">
								No hay Asignaciones para mostrar
							</article>
						)}
					</article>
					{seeDetail === true && (
						<Redirect to="/worker-company/assignments/view-activities" />
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default CrudRegAssignmentsApp;
